<template>
  <div id="prize">
    <center>
      <b>
        LEGO® UNIVERSE "JAWBOX DONATION" PLAY SESSION GIVEAWAY
        <p></p>
        <p>OFFICIAL RULES</p>
        <p>
          LEGO® UNIVERSE GAME AND ACTIVE SUBSCRIPTION REQUIRED
          <br>
          MANY WILL ENTER; FEW WILL WIN A PRIZE
        </p>
      </b>
    </center>
    <p>
      <b>1.&nbsp;OVERVIEW:</b>
      &nbsp; Eligible LEGO® Universe fans from the US, the UK, Canada and Germany who log in and participate in the game activity specified below between 12:01 a.m. EST Tuesday, May 3, 2011 and until the community mission finishes, no later than 11:59 p.m. EST Wednesday, June 15, 2011, will be entered to win the following prize packages:
    </p>
    <p>Top 1 winner:
      <a href="https://web.archive.org/web/20110507060238/http://shop.lego.com/product/?p=21009&amp;LangId=2057&amp;ShipTo=US">LEGO Architecture – Farnsworth House (#21009)</a>,
      <a href="https://web.archive.org/web/20110507060238/http://shop.lego.com/product/?p=5508&amp;LangId=2057&amp;ShipTo=US">1 LEGO Large Brick Box (#5508)</a>,
      <a href="https://web.archive.org/web/20110507060238/http://shop.lego.com/product/?p=630&amp;LangId=2057&amp;ShipTo=US">1 LEGO Brick Separator (#630) </a>,
      <a href="https://web.archive.org/web/20110507060238/http://shop.lego.com/product/?p=55000&amp;LangId=2057&amp;ShipTo=US">1 copy of LEGO® Universe Massively Multiplayer Online Game (#55000)</a>,
      an in-game item exclusive to this contest: The Hood of the Mystic, 20 in-game Faction tokens.
    </p>
    <p>Top 2-20 Winners:
      <a href="https://web.archive.org/web/20110507060238/http://shop.lego.com/product/?p=5508&amp;LangId=2057&amp;ShipTo=US">1 LEGO Large Brick Box (#5508) </a>,
      <a href="https://web.archive.org/web/20110507060238/http://shop.lego.com/product/?p=630&amp;LangId=2057&amp;ShipTo=US">1 LEGO Brick Separator (#630) </a>,
      <a href="https://web.archive.org/web/20110507060238/http://shop.lego.com/product/?p=55000&amp;LangId=2057&amp;ShipTo=US">1 copy of LEGO® Universe Massively Multiplayer Online Game (#55000)</a>,
      an in-game item exclusive to this contest: The Hood of the Mystic, 20 in-game Faction tokens.
    </p>
    <p>
      <b>2.&nbsp;HOW TO ENTER:</b>
      &nbsp; Log on to the LEGO Universe online game and participate in the Jawbox Community Donation mission in the Nimbus Station zone between 12:01 a.m. EST Tuesday, April 19th, 2011 and and until the community mission finishes, no later than 11:59 p.m. EST Wednesday, June 15th, 2011. To win a prize, you must go to the Nimbus Station zone, and donate bricks in the Jawbox. No player will be awarded more than one prize.
      <br><br>
      <b>LEGO® UNIVERSE GAME AND ACTIVE SUBSCRIPTION REQUIRED TO ENTER AND WIN.</b>
      <br><br>
      <b>3.&nbsp;HOW TO WIN:</b>
      &nbsp; Once you log in and complete the requirements, you will be automatically entered into the drawing; there is nothing more you need to do. Winners will be selected based on the number of bricks they donate in the Nexus Jawbox. Decisions will be based on the subjective opinions of the Judges, and will be final.
      <br><br>
      <b>MANY WILL ENTER; FEW WILL WIN A PRIZE.</b>
      <br><br>
      <b>ELIGIBILITY:</b>
      &nbsp; This promotion is open only to LEGO Universe online game players, who are residents of the 50 United States plus the District of Columbia, the United Kingdom, Germany, and Canada, except for the Province of Quebec.&nbsp;Employees of the LEGO Group and members of their immediate families and households are not eligible to participate.&nbsp;Additionally, eligibility is limited to those who have not won a prize from any LEGO Universe promotion in the 30 days prior to the Prize Award date.
      <br>
      Neither the length of time you are logged in and playing, nor the number of times you log in and out during the play session will have any effect on your chances of winning a prize.
      <br>
      All national, regional, and local laws and regulations apply. Void where prohibited by law.
      <br><br>
      <b>4.&nbsp;AWARD&nbsp; OF PRIZES:</b>
      &nbsp; Twenty (20) winners will be picked from among all eligible entries on or about June 24th, 2011 by members of the LEGO Universe Judge Team, whose decisions will be final.&nbsp; Odds of winning will depend upon the number of eligible entries received, and the skill of the entrant.
      <br><br>
      <b>5.&nbsp;PRIZES AND APPROXIMATE RETAIL VALUES:</b>
      &nbsp; There will be twenty (20) sets of prizes awarded.
    </p>
    <p>Top 1 winner:
      <a href="https://web.archive.org/web/20110507060238/http://shop.lego.com/product/?p=21009&amp;LangId=2057&amp;ShipTo=US">LEGO Architecture – Farnsworth House (#21009)</a>,
      <a href="https://web.archive.org/web/20110507060238/http://shop.lego.com/product/?p=5508&amp;LangId=2057&amp;ShipTo=US">1 LEGO Large Brick Box (#5508)</a>,
      <a href="https://web.archive.org/web/20110507060238/http://shop.lego.com/product/?p=630&amp;LangId=2057&amp;ShipTo=US">1 LEGO Brick Separator (#630)</a>,
      <a href="https://web.archive.org/web/20110507060238/http://shop.lego.com/product/?p=55000&amp;LangId=2057&amp;ShipTo=US">1 copy of LEGO® Universe Massively Multiplayer Online Game (#55000)</a>,
      an in-game item exclusive to this contest: The Hood of the Mystic, 20 in-game Faction tokens. (Estimated value: $132.46 USD)
    </p>
    <p>Winners 2 – 20:
      <a href="https://web.archive.org/web/20110507060238/http://shop.lego.com/product/?p=5508&amp;LangId=2057&amp;ShipTo=US">1 LEGO Large Brick Box (#5508)</a>,
      <a href="https://web.archive.org/web/20110507060238/http://shop.lego.com/product/?p=630&amp;LangId=2057&amp;ShipTo=US">1 LEGO Brick Separator (#630) </a>,
      <a href="https://web.archive.org/web/20110507060238/http://shop.lego.com/product/?p=55000&amp;LangId=2057&amp;ShipTo=US">1 copy of LEGO® Universe Massively Multiplayer Online Game (#55000)</a>,
      an in-game item exclusive to this contest: The Hood of the Mystic, 20 in-game Faction tokens. (Estimated value: $72.47 USD)
    </p>
    <p>
      No substitution of prizes will be allowed.
      <br><br>
      <b>6.&nbsp; GENERAL RULES:</b>
    </p>
    <p>Winners will be notified by email to the winner's email address of record.</p>
    <p>
      No substitution of prize or transfer of prize to another person is permitted.
      Sponsor reserves the right to substitute a prize of equal or greater value in the unlikely
      event that the intended prize is not available, or in order to award a prize appropriate
      for the age of the winner.
    </p>
    <p>All decisions of the Judges are final.</p>
    <p>
      All income taxes and fees and/or duties, if applicable, are the sole responsibility of
      each winner.
    </p>
    <p>
      In no event will more than the stated number of prizes be awarded.<br>
      If the Judges suspect that a player has attempted to gain an unfair advantage over other
      players by using unauthorized methods to manipulate game play, that player will be disqualified,
      and all entries submitted by the player will be declared null and void.
      <br>
      By accepting a prize, winners (and their parent or legal guardian if winner is a minor)
      agree to hold Sponsor, its directors, officers, employees, and assigns harmless against any
      and all claims and liability arising out of use of the prize. Winners (and their parent or
      legal guardian if winner is a minor) assume all liability for any injury or damage caused,
      or claimed to be caused, by participation in this promotion or use or redemption of any
      prize.
      Acceptance of a prize constitutes permission for the Sponsor to use winner's name, likeness,
      and entry submission for purposes of advertising and trade, including posting the winning
      entries on the LEGO Universe Community webpage, without further compensation, and to use a
      winner's name for purposes of publishing a winners list, unless prohibited by law.
    </p>
    <p>
      Prizes will be mailed to each winner when available, but not later than 90 days from the
      prize award date.
      It is the responsibility of the winner to inform the Sponsor of the proper shipping address.
      <br><br>
      Entrants (and their parent or legal guardian if entrant is a minor) agree to release,
      discharge and hold harmless Sponsor, its directors, officers, employees and assigns, harmless
      from and against any and all liability and damages.
      By participating in this promotion, entrants (and their parent or legal guardian if entrant
      is a minor) agree to be bound by the Official Rules and the judges’ decisions, which are
      final.
      In the event there is a discrepancy or inconsistency between disclosures or other statements
      contained in any promotion materials and the terms and conditions of the Official Rules,
      the Official Rules shall prevail, govern and control.
      Sponsor is not responsible for any typographical or other error in the printing of the offer,
      administration of the giveaway promotion, or in the announcement of the prizes.
    </p>
    <p>
      <b>Special rules for United States residents:</b>
      Winners who are residents of the United States (or a parent or legal guardian if a potential
      winner is deemed a minor in his/her state of residence) will be required to complete and
      return an Affidavit of Eligibility, Release of Liability, and a Prize Acceptance Form within
      5 days of such forms being emailed.
      Noncompliance within this time period or return of any prize or prize notification as
      undeliverable will result in disqualification without further notice and an alternate winner
      will be selected.
      (End of US residents special rules).
    </p>
    <p>
      <b>Special rules for Canadian residents:</b>
      To be declared a winner, residents of Canada (or a parent or guardian if a potential winner
      is deemed a minor in his/her province or territory of residence) must correctly answer an
      arithmetical skill-testing question and execute a Declaration and Release Form to release
      Sponsor and its agencies and representatives from liability with respect to contests and
      prizes within 5 days of such form being emailed.
      Noncompliance within this time period or return of any prize or prize notification as
      undeliverable will result in disqualification without further notice and an alternate winner
      will be selected.
      (End of Canadian residents special rules).
    </p>
    <p>
      Not responsible for faulty, incorrect or mis-transcribed email transmissions, incorrect
      announcements of any kind, technical hardware or software failures of any kind including
      any injury or damage to any person's computer related to or resulting from participating in
      or experiencing any materials in connection with the promotion, lost or unavailable network
      connections, or failed, incomplete, garbled or delayed computer transmission that may limit
      a user's ability to participate in the promotion.
      Sponsor assumes no responsibility for undeliverable emails resulting from any form of active
      or passive email filtering by a user's internet service provider and/or email client or for
      insufficient space in user's email account to receive email.
      Sponsor reserves the right to cancel or modify the promotion if fraud, misconduct or technical
      failures destroy the integrity of the program; or if a computer virus, bug, or other technical
      problem corrupts the administration or security of the program as determined by Sponsor, in
      its sole discretion.
      In the event of termination of online entry, a notice will be posted online indicating how to
      enter via the mail.
      The drawing will be conducted from among all eligible online entries received prior to
      termination and all eligible mail-in entries received.
      Any damage made to the Web Site will be the responsibility of the authorized email account
      holder of the email address of record.
      Proof of submitting entries will not be deemed to be proof of receipt by Sponsor.
      Any entries which are suspected of being fraudulent (including those using robotic, automatic,
      programmed or similar methods of participation) will be disqualified, based on determinations
      made solely by Sponsor.
      Sponsor reserves the right to prohibit the participation of an individual if fraud or tampering
      is suspected or if the account holder fails to comply with any requirement of participation
      as stated herein or with any provision in these Official Rules.
    </p>
    <p>
      <b>Notice:</b>
      Any attempt by an individual to deliberately damage any web site or undermine the legitimate
      operation of this promotion is a violation of criminal and civil laws, and should such an
      attempt be made, sponsor reserves the right to seek damages from any such individual to the
      fullest extent permitted by law.
      <br><br>
      <b>7.&nbsp;WINNERS:</b>
    </p>
    <p>
      For the names of the winners, send a separate, stamped, self-addressed (#10) envelope to:
      LEGO® Universe "Jawbox Donation Contest”, LEGO Systems, Inc., 1795 Dogwood St, Suite 240,
      Louisville, CO 80027, USA, to be received by June 20, 2011.
      Residents of Canada and the U.S. State of Vermont may omit return postage.
      <br><br>
      <b>8.&nbsp;SPONSOR:</b>
      LEGO Systems, Inc., 555 Taylor Road, Enfield, CT 06083, USA.
    </p>
    <p>LEGO is a trademark of the LEGO Group of Companies. © 2011 The LEGO Group. All rights reserved.</p>
    <p></p>
  </div>
</template>
